class Menu {
	constructor() {
		let buttons = $('[data-menu-toggle]');
		buttons.on('click', this.buttonPressed);
		if ($(window).width() > 1280) {
			buttons.on('mouseover', this.sourceMouseOver);
		}
		document.addEventListener('click', this.clickOnDocument);
		document.addEventListener('mousemove', this.mouseMove);
		//document.addEventListener('scroll', () => this.mainMenuScrolled());
		//this.mainMenuScrolled();
	}

	clickOnDocument(event) {
		if (
			!Menu.isAllClosed() &&
			Array.from(document.querySelectorAll('[data-menu-toggle]')).filter(
				button => event.composedPath().includes(button),
			).length === 0
		) {
			const target = document.querySelector('[data-menu].visible');
			if (!event.composedPath().includes(target)) {
				Menu.close($(target).data('menu'));
			}
		}
	}

	//mainMenuScrolled() {
	//	const mainMenu = $('#main-menu');
	//	if (mainMenu) {
	//		if (window.scrollY >= 150) {
	//			mainMenu.addClass('scrolled');
	//		} else {
	//			mainMenu.removeClass('scrolled');
	//		}
	//	}
	//}

	sourceMouseOver(event) {
		const hamburger = $('.menu-burger');
		const element = $(event.currentTarget);
		const id = element.data('menu-toggle');
		if (!Menu.isVisible(id)) {
			Menu.open(id);
		}
		if (Menu.isAllClosed()) {
			hamburger.addClass('x');
		}
	}

	buttonPressed(event) {
		const hamburger = $('.menu-burger');
		const element = $(event.currentTarget);
		const id = element.data('menu-toggle');
		if (Menu.isVisible(id)) {
			Menu.close(id);
		} else {
			Menu.open(id);
		}
		if (Menu.isAllClosed()) {
			hamburger.removeClass('x');
		} else {
			hamburger.addClass('x');
		}
	}

	static remoteElement(id) {
		return $('[data-menu-toggle="' + id + '"]');
	}

	static element(id) {
		return $('[data-menu="' + id + '"]');
	}

	static isVisible(id) {
		return Menu.element(id).hasClass('visible');
	}

	static isAllClosed() {
		return $('[data-menu].visible').length === 0;
	}

	static close(id) {
		Menu.element(id).stop().fadeOut(250).removeClass('visible');
		Menu.remoteElement(id).removeClass('active');
	}

	static open(id) {
		$('[data-menu]:not([data-menu="' + id + '"])')
			.fadeOut(250)
			.removeClass('visible');
		$(
			'[data-menu-toggle]:not([data-menu-toggle="' + id + '"])',
		).removeClass('active');
		Menu.element(id).stop().fadeIn(250).addClass('visible');
		Menu.remoteElement(id).addClass('active');
	}

	mouseMove() {
		const visibleMenus = $('[data-menu].visible');
		if (visibleMenus.length > 0) {
			visibleMenus.each((i, e) => {
				const el = $(e);
				if (!el.is(':hover') && el.hasClass('mouseIn')) {
					Menu.close(el.data('menu'));
					el.removeClass('mouseIn');
				} else if (el.is(':hover') && !el.hasClass('mouseIn')) {
					el.addClass('mouseIn');
				}
			});
		}
	}
}

window.Menu = new Menu();
